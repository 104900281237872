.element {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}
body{
  overflow-x: hidden;
}

.element .intro {
  background-color: #76b043;
  height: 900px;
  position: relative;
  width: 1920px;
}

.element .overlap {
  height: 900px;
  left: 888px;
  position: absolute;
  top: 0;
  width: 1032px;
}

.element .img {
  height: 900px;
  left: 93px;
  position: absolute;
  top: 0;
  width: 939px;
}

.element .overlap-wrapper {
  background-color: #ffffff;
  border: 10px solid;
  border-color: #e5e5e5;
  border-radius: 13.35px;
  box-shadow: 0.81px 1.28px 2.58px #00000004, 3.58px 5.63px 5.34px #00000007, 8.79px 13.82px 10.64px #00000009,
    16.93px 26.61px 20.87px #0000000b, 28.49px 44.78px 38.36px #0000000e, 43.96px 69.08px 65.51px #00000012;
  height: 685px;
  left: 135px;
  overflow: hidden;
  position: absolute;
  top: 116px;
  transform: rotate(10.38deg);
  width: 327px;
}

.element .overlap-group {
  height: 1800px;
  left: -5px;
  position: relative;
  top: -24px;
  width: 321px;
}

.element .screen-instance {
  height: 665px !important;
  left: 5px !important;
  position: absolute !important;
  top: 24px !important;
  width: 307px !important;
}

.element .design-component-instance-node {
  font-size: 59px !important;
  left: 63px !important;
  top: 263px !important;
}

.element .main {
  background-color: #ffffff;
  height: 1798px;
  left: 5px;
  overflow: hidden;
  position: absolute;
  top: 1px;
  transform: rotate(-0.29deg);
  width: 312px;
}

.element .div {
  height: 1678px;
  left: 0;
  position: absolute;
  top: 83px;
  width: 423px;
}

.element .rectangle {
  background-color: #ffffff;
  height: 229px;
  left: 0;
  position: absolute;
  top: 825px;
  width: 312px;
}

.element .group {
  height: 1678px;
  left: 0;
  position: absolute;
  top: 0;
  width: 423px;
}

.element .overlap-2 {
  height: 544px;
  left: 0;
  position: absolute;
  top: -24px;
  width: 457px;
}

.element .rectangle-2 {
  height: 518px;
  left: 68px;
  position: absolute;
  top: 0;
  width: 390px;
}

.element .frame {
  align-items: center;
  display: inline-flex;
  gap: 6.93px;
  left: 14px;
  position: absolute;
  top: 48px;
}

.element .ellipse {
  height: 35.52px;
  margin-left: -0.92px;
  margin-top: -9.69px;
  object-fit: cover;
  position: relative;
  width: 35.52px;
}

.element .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3.46px;
  position: relative;
}

.element .div-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.73px;
  position: relative;
}

.element .text-wrapper {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  height: 13.86px;
  letter-spacing: 0;
  line-height: 13.9px;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 98.73px;
}

.element .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.element .text-wrapper-2 {
  color: #ffffff;
  font-family: "F37 Ginger-Light", Helvetica;
  font-size: 10.4px;
  font-weight: 300;
  height: 17.32px;
  letter-spacing: 0;
  line-height: 17.3px;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 45.04px;
}

.element .keyboard-arrow-down {
  height: 12.05px;
  margin-bottom: -1.31px;
  margin-right: -0.48px;
  position: relative;
  width: 12.05px;
}

.element .frame-wrapper {
  height: 46px;
  left: 14px;
  position: absolute;
  top: 398px;
  width: 284px;
}

.element .frame-4 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 284px;
}

.element .group-2 {
  height: 30.31px;
  position: relative;
  width: 91.21px;
}

.element .p {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 17.3px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 54px;
}

.element .span {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17.3px;
}

.element .text-wrapper-3 {
  font-family: "F37 Ginger-Bold", Helvetica;
  font-weight: 700;
}

.element .group-3 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 93px;
}

.element .text-wrapper-4 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 16px;
}

.element .text-wrapper-5 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 67px;
}

.element .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.39px;
  position: relative;
}

.element .overlap-group-wrapper {
  height: 45.9px;
  position: relative;
  width: 49.9px;
}

.element .overlap-group-2 {
  background-color: #00000099;
  border-radius: 22.95px;
  height: 46px;
  position: relative;
  width: 46px;
}

.element .text-wrapper-6 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  height: 14px;
  left: 13px;
  letter-spacing: 0;
  line-height: 13.9px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
  width: 20px;
}

.element .text-wrapper-7 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  height: 10px;
  left: 10px;
  letter-spacing: 0;
  line-height: 9.5px;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
  width: 24px;
}

.element .overlap-group-3 {
  background-color: #00000099;
  border-radius: 22.52px;
  height: 46px;
  position: relative;
  width: 46px;
}

.element .text-wrapper-8 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  height: 14px;
  left: 13px;
  letter-spacing: 0;
  line-height: 13.9px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
  width: 17px;
}

.element .text-wrapper-9 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 17px;
  letter-spacing: 0;
  line-height: 9.5px;
  position: absolute;
  text-align: center;
  top: 25px;
  white-space: nowrap;
  width: 9px;
}

.element .group-wrapper {
  height: 45.9px;
  position: relative;
  width: 45.9px;
}

.element .group-4 {
  height: 46px;
  width: 50px;
}

.element .text-wrapper-10 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  height: 17px;
  left: 16px;
  letter-spacing: 0;
  line-height: 17.3px;
  position: absolute;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 15px;
}

.element .text-wrapper-11 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 9px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 26px;
  white-space: nowrap;
  width: 29px;
}

.element .frame-6 {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  gap: 8.66px;
  height: 49px;
  left: 0;
  padding: 17.32px 13.86px;
  position: absolute;
  top: 495px;
  width: 97px;
}

.element .text-wrapper-12 {
  color: #77b933;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 14.72px;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-top: -1.3px;
  position: relative;
  text-decoration: underline;
  width: 54.56px;
}

.element .frame-7 {
  align-items: center;
  background-color: #f3f3f3;
  display: flex;
  gap: 8.66px;
  height: 49px;
  left: 200px;
  padding: 17.32px 5.2px 17.32px 13.86px;
  position: absolute;
  top: 495px;
  width: 112px;
}

.element .text-wrapper-13 {
  color: #808080;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 14.72px;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-top: -1.3px;
  position: relative;
  width: 85.74px;
}

.element .frame-8 {
  align-items: center;
  background-color: #f3f3f3;
  display: inline-flex;
  gap: 8.66px;
  height: 49px;
  justify-content: center;
  left: 97px;
  padding: 18.19px 13.86px;
  position: absolute;
  top: 495px;
}

.element .text-wrapper-14 {
  color: #808080;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 14.72px;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-bottom: -0.43px;
  margin-top: -2.17px;
  position: relative;
  width: 75.35px;
}

.element .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13.86px;
  left: 14px;
  position: absolute;
  top: 1510px;
}

.element .text-wrapper-15 {
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 13.9px;
  font-weight: 400;
  height: 19.05px;
  letter-spacing: -0.21px;
  line-height: 19.4px;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 40.71px;
}

.element .frame-10 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.86px;
  position: relative;
  width: 284.08px;
}

.element .text-wrapper-16 {
  color: #3d3d3d;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 103.93px;
  letter-spacing: -0.18px;
  line-height: 17px;
  margin-top: -0.87px;
  position: relative;
  width: 280.61px;
}

.element .frame-11 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 3.46px;
  position: relative;
}

.element .text-wrapper-17 {
  color: #20ab9a;
  font-family: "SF Pro Text-Medium", Helvetica;
  font-size: 12.1px;
  font-weight: 500;
  height: 17.32px;
  letter-spacing: -0.18px;
  line-height: 17px;
  margin-top: -0.87px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: 61.49px;
}

.element .keyboard-arrow-down-2 {
  height: 17.32px;
  margin-left: -2897.41px;
  margin-top: -10628.52px;
  position: relative;
  width: 17.32px;
}

.element .group-5 {
  height: 606px;
  left: 0;
  position: absolute;
  top: 627px;
  width: 312px;
}

.element .frame-12 {
  background-color: #ffffff;
  height: 260px;
  left: 0;
  position: absolute;
  top: 0;
  width: 312px;
}

.element .overlap-group-4 {
  height: 198px;
  left: -3093px;
  position: absolute;
  top: -9596px;
  width: 318px;
}

.element .rectangle-3 {
  height: 198px;
  left: 88px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 230px;
}

.element .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 105px;
}

.element .group-6 {
  height: 66px;
  left: 14px;
  position: absolute;
  top: 43px;
  width: 111px;
}

.element .frame-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6.93px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 111px;
}

.element .text-wrapper-18 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 10.39px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 74.48px;
}

.element .text-wrapper-19 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 10.39px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 88.34px;
}

.element .text-wrapper-20 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 10.39px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 98.73px;
}

.element .frame-14 {
  align-items: center;
  display: flex;
  gap: 3.46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 111px;
}

.element .thumb-up-wrapper {
  background-color: #20ab9a;
  border-radius: 6.93px;
  height: 13.86px;
  position: relative;
  width: 13.86px;
}

.element .thumb-up {
  height: 7px;
  left: -3133px;
  position: absolute;
  top: -9644px;
  width: 7px;
}

.element .text-wrapper-21 {
  color: #292929;
  font-family: "SF Pro Text-Bold", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  height: 12.13px;
  letter-spacing: -0.16px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 64.96px;
}

.element .group-7 {
  height: 66px;
  left: 14px;
  position: absolute;
  top: 151px;
  width: 118px;
}

.element .frame-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6.93px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 118px;
}

.element .text-wrapper-22 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 10.39px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 104.8px;
}

.element .text-wrapper-23 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 10.39px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 97.87px;
}

.element .text-wrapper-24 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 10.39px;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 106.53px;
}

.element .frame-16 {
  align-items: center;
  display: flex;
  gap: 3.46px;
  left: 0;
  position: absolute;
  top: 0;
  width: 118px;
}

.element .img-wrapper {
  background-color: #d82e2e;
  border-radius: 6.93px;
  height: 13.86px;
  position: relative;
  width: 13.86px;
}

.element .thumb-up-2 {
  height: 7px;
  left: -3114px;
  position: absolute;
  top: -9742px;
  width: 7px;
}

.element .text-wrapper-25 {
  color: #292929;
  font-family: "SF Pro Text-Medium", Helvetica;
  font-size: 10.4px;
  font-weight: 500;
  height: 12.13px;
  letter-spacing: -0.16px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 77.08px;
}

.element .group-8 {
  height: 346px;
  left: 0;
  position: absolute;
  top: 260px;
  width: 312px;
}

.element .rectangle-4 {
  height: 346px;
  left: -3054px;
  object-fit: cover;
  position: absolute;
  top: -8911px;
  width: 312px;
}

.element .frame-17 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 20.79px;
  justify-content: center;
  left: 44px;
  position: absolute;
  top: 110px;
}

.element .text-wrapper-26 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 15.6px;
  font-weight: 400;
  height: 67.56px;
  letter-spacing: -0.23px;
  line-height: 22.5px;
  margin-top: -0.87px;
  position: relative;
  text-align: center;
  width: 223.45px;
}

.element .frame-18 {
  align-items: center;
  background-color: #77b933;
  border-radius: 3.46px;
  display: flex;
  gap: 8.66px;
  height: 38.11px;
  justify-content: center;
  padding: 8.66px;
  position: relative;
  width: 103.93px;
}

.element .text-wrapper-27 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 13.9px;
  font-weight: 700;
  height: 14.72px;
  letter-spacing: -0.62px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: 51.1px;
}

.element .group-9 {
  height: 260px;
  left: 0;
  position: absolute;
  top: 1233px;
  width: 425px;
}

.element .overlap-3 {
  height: 260px;
  position: relative;
}

.element .rectangle-5 {
  background-color: #efefef;
  height: 260px;
  left: 0;
  position: absolute;
  top: 0;
  width: 312px;
}

.element .group-10 {
  height: 149px;
  left: 291px;
  position: absolute;
  top: 52px;
  width: 134px;
}

.element .rectangle-6 {
  height: 149px;
  left: -2998px;
  position: absolute;
  top: -8942px;
  width: 132px;
}

.element .group-11 {
  height: 28px;
  left: 12px;
  position: absolute;
  top: 89px;
  width: 96px;
}

.element .frame-19 {
  align-items: center;
  display: inline-flex;
  gap: 3.46px;
  left: 27px;
  position: absolute;
  top: 18px;
}

.element .ellipse-2 {
  background-color: #c4c4c4;
  border-radius: 1.3px;
  height: 2.6px;
  position: relative;
  width: 2.6px;
}

.element .text-wrapper-28 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 9.53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 21.65px;
}

.element .frame-20 {
  align-items: center;
  display: inline-flex;
  gap: 3.46px;
  left: 58px;
  position: absolute;
  top: 18px;
}

.element .text-wrapper-29 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 9.53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 30.31px;
}

.element .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  gap: 1.73px;
  left: 0;
  position: absolute;
  top: 18px;
}

.element .star {
  height: 6.93px;
  margin-left: -3295.33px;
  margin-top: -10409.76px;
  position: relative;
  width: 7.79px;
}

.element .text-wrapper-30 {
  color: #ffffff;
  font-family: "F37 Ginger-Regular", Helvetica;
  font-size: 8.7px;
  font-weight: 400;
  height: 9.53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.87px;
  position: relative;
  white-space: nowrap;
  width: 13.86px;
}

.element .text-wrapper-31 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  height: 11px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 56px;
}

.element .text-wrapper-32 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 128px;
  white-space: nowrap;
  width: 80px;
}

.element .group-12 {
  height: 146px;
  left: 152px;
  position: absolute;
  top: 55px;
  width: 132px;
}

.element .group-13 {
  height: 146px;
  position: relative;
}

.element .rectangle-7 {
  height: 146px;
  left: -3000px;
  object-fit: cover;
  position: absolute;
  top: -8918px;
  width: 132px;
}

.element .group-14 {
  height: 46px;
  left: 12px;
  position: absolute;
  top: 89px;
  width: 104px;
}

.element .frame-22 {
  align-items: center;
  display: inline-flex;
  gap: 3.46px;
  left: 30px;
  position: absolute;
  top: 16px;
}

.element .frame-23 {
  align-items: center;
  display: inline-flex;
  gap: 3.46px;
  left: 64px;
  position: absolute;
  top: 16px;
}

.element .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  gap: 1.73px;
  left: 0;
  position: absolute;
  top: 16px;
}

.element .star-2 {
  height: 6.93px;
  margin-left: -3159.17px;
  margin-top: -10385.53px;
  position: relative;
  width: 7.79px;
}

.element .text-wrapper-33 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  height: 11px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 74px;
}

.element .text-wrapper-34 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 10.4px;
  position: absolute;
  top: 36px;
  white-space: nowrap;
  width: 88px;
}

.element .text-wrapper-35 {
  color: #000000;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 15.6px;
  font-weight: 700;
  left: 14px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 26px;
  white-space: nowrap;
  width: 94px;
}

.element .group-15 {
  height: 146px;
  left: 14px;
  position: absolute;
  top: 55px;
  width: 132px;
}

.element .rectangle-8 {
  height: 146px;
  left: -3002px;
  object-fit: cover;
  position: absolute;
  top: -8893px;
  width: 132px;
}

.element .group-16 {
  height: 49px;
  left: 10px;
  position: absolute;
  top: 88px;
  width: 96px;
}

.element .group-17 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 96px;
}

.element .star-3 {
  height: 6.93px;
  margin-left: -3019.46px;
  margin-top: -10363.31px;
  position: relative;
  width: 7.79px;
}

.element .text-wrapper-36 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 10.4px;
  font-weight: 700;
  height: 11px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 81px;
}

.element .text-wrapper-37 {
  color: #ffffff;
  font-family: "F37 Ginger-Bold", Helvetica;
  font-size: 8.7px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 10.4px;
  position: absolute;
  top: 38px;
  white-space: nowrap;
  width: 78px;
}

.element .frame-25 {
  align-items: flex-start;
  display: inline-flex;
  gap: 3.46px;
  left: 142px;
  position: absolute;
  top: 226px;
}

.element .ellipse-3 {
  background-color: #77b933;
  border-radius: 3.46px;
  height: 6.93px;
  position: relative;
  width: 6.93px;
}

.element .ellipse-4 {
  background-color: #c4c4c4;
  border-radius: 3.46px;
  height: 6.93px;
  position: relative;
  width: 6.93px;
}

.element .group-18 {
  height: 69px;
  left: 14px;
  position: absolute;
  top: 537px;
  width: 291px;
}

.element .group-19 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 0;
  width: 94px;
}

.element .overlap-group-5 {
  background-color: #ebebeb;
  border-radius: 3.46px;
  height: 69px;
  position: relative;
  width: 90px;
}

.element .text-wrapper-38 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 31px;
  letter-spacing: -0.12px;
  line-height: 10.9px;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
  width: 29px;
}

.element .text-wrapper-39 {
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 17px;
  left: 21px;
  letter-spacing: -0.18px;
  line-height: 17px;
  position: absolute;
  text-align: center;
  top: 30px;
  white-space: nowrap;
  width: 48px;
}

.element .group-20 {
  height: 69px;
  left: 100px;
  position: absolute;
  top: 0;
  width: 94px;
}

.element .text-wrapper-40 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  height: 11px;
  left: 11px;
  letter-spacing: -0.12px;
  line-height: 10.9px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 68px;
}

.element .text-wrapper-41 {
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 17px;
  left: 19px;
  letter-spacing: -0.18px;
  line-height: 17px;
  position: absolute;
  text-align: center;
  top: 30px;
  white-space: nowrap;
  width: 51px;
}

.element .group-21 {
  height: 69px;
  left: 201px;
  position: absolute;
  top: 0;
  width: 94px;
}

.element .text-wrapper-42 {
  color: #000000;
  font-family: "SF Pro Text-Regular", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  height: 11px;
  left: 23px;
  letter-spacing: -0.12px;
  line-height: 10.9px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 46px;
}

.element .text-wrapper-43 {
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  height: 17px;
  left: 26px;
  letter-spacing: -0.18px;
  line-height: 17px;
  position: absolute;
  text-align: center;
  top: 30px;
  white-space: nowrap;
  width: 39px;
}

.element .rectangle-9 {
  background-color: #e0c901;
  height: 3px;
  left: 0;
  position: absolute;
  top: 468px;
  width: 213px;
}

.element .group-22 {
  background-color: #f0f0f0;
  border-radius: 19.05px;
  box-shadow: -2.6px 5.2px 55.43px #2828286b;
  height: 38px;
  left: 256px;
  position: absolute;
  top: 23px;
  width: 38px;
}

.element .group-23 {
  height: 23px;
  left: 10px;
  position: relative;
  top: 7px;
  width: 22px;
}

.element .overlap-group-6 {
  height: 25px;
  position: relative;
  top: -1px;
  width: 20px;
}

.element .keyboard-arrow-up {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.element .text-wrapper-44 {
  color: #6e6e6e;
  font-family: "SF Pro Text-Medium", Helvetica;
  font-size: 4.3px;
  font-weight: 500;
  height: 6px;
  left: 0;
  letter-spacing: 0;
  line-height: 6.1px;
  position: absolute;
  text-align: center;
  top: 19px;
  white-space: nowrap;
  width: 20px;
}

.element .overlap-4 {
  height: 84px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 314px;
}

.element .group-24 {
  background-color: #76b043;
  height: 62px;
  left: 1px;
  position: absolute;
  top: 22px;
  width: 312px;
}

.element .group-25 {
  height: 47px;
  left: 11px;
  position: relative;
  top: 8px;
  width: 79px;
}

.element .overlap-group-7 {
  height: 57px;
  position: relative;
  top: -3px;
  width: 82px;
}

.element .image {
  height: 57px;
  left: 15px;
  position: absolute;
  top: 0;
  width: 67px;
}

.element .color-wrapper {
  height: 21px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 21px;
}

.element .color {
  height: 13px;
  left: 2px;
  position: absolute;
  top: 4px;
  width: 17px;
}

.element .rectangle-10 {
  background-color: #000000;
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  width: 314px;
}

.element .group-26 {
  height: 21px;
  left: 243px;
  position: absolute;
  top: 43px;
  width: 56px;
}

.element .frame-26 {
  align-items: center;
  display: flex;
  gap: 13.86px;
  justify-content: flex-end;
  position: relative;
  width: 56px;
}

.element .search {
  height: 24.11px;
  margin-left: -0.53px;
  margin-top: -4.62px;
  position: relative;
  width: 24.11px;
}

.element .pin-drop {
  height: 24.11px;
  margin-bottom: -4.77px;
  margin-right: -1.39px;
  position: relative;
  width: 24.11px;
}

.element .text-wrapper-45 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 34px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  top: 70px;
}

.element .text-wrapper-46 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 34px;
  left: 559px;
  letter-spacing: -0.39px;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 70px;
}

.element .group-27 {
  height: 508px;
  left: 125px;
  position: absolute;
  top: 212px;
  width: 727px;
}

.element .context {
  height: 208px;
  left: 0;
  position: absolute;
  top: 0;
  width: 729px;
}

.element .text-wrapper-47 {
  color: #f3f3f3;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 80px;
  font-weight: 700;
  height: 208px;
  left: 0;
  letter-spacing: -2.39px;
  line-height: 104px;
  position: absolute;
  top: 0;
  width: 727px;
}

.element .scope-of-work {
  height: 120px;
  left: 2px;
  position: absolute;
  top: 388px;
  width: 727px;
}

.element .overlap-5 {
  height: 156px;
  left: -32px;
  position: absolute;
  top: 0;
  width: 460px;
}

.element .UIX {
  height: 120px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 296px;
}

.element .SM {
  height: 56px;
  left: 280px;
  position: absolute;
  top: 64px;
  width: 180px;
}

.element .overlap-6 {
  background-color: #614ad3;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 178px;
}

.element .text-wrapper-48 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 65px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.element .group-28 {
  background-color: #ffffff;
  border-radius: 23.06px;
  height: 46px;
  left: 6px;
  position: absolute;
  top: 5px;
  width: 46px;
}

.element .overlap-group-8 {
  height: 21px;
  left: 12px;
  position: relative;
  top: 12px;
  width: 21px;
}

.element .line-2 {
  height: 21px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .line-3 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 21px;
}

.element .text-wrapper-49 {
  color: #fffffe;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  height: 40px;
  left: 32px;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .WD {
  height: 56px;
  left: 442px;
  position: absolute;
  top: 64px;
  width: 285px;
}

.element .overlap-7 {
  background-color: #2934d0;
  border-radius: 28px;
  height: 56px;
  position: relative;
  width: 283px;
}

.element .text-wrapper-50 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 23.1px;
  font-weight: 700;
  height: 33px;
  left: 64px;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

.element .image-2 {
  height: 152px;
  left: 91px;
  position: absolute;
  top: 17px;
  width: 193px;
}

.element .the-challenge {
  align-items: center;
  background-color: #133a35;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 64px 0px;
  position: relative;
  width: 1920px;
}

.element .group-29 {
  height: 287px;
  position: relative;
  width: 1511.98px;
}

.element .text-wrapper-51 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 28px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0.83px;
  line-height: normal;
  position: absolute;
  top: 0;
}

.element .OTO-capital-brings {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.24px;
  line-height: 44px;
  position: absolute;
  top: 67px;
  width: 1508px;
}

.element .div-2 {
  height: 970px;
  position: relative;
  width: 1920px;
}

.element .group-30 {
  height: 970px;
}

.element .rectangle-11 {
  background-color: #ffffff;
  height: 690px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.element .rectangle-12 {
  background-color: #ebebeb;
  height: 280px;
  left: 0;
  position: absolute;
  top: 690px;
  width: 1920px;
}

.element .frame-27 {
  height: 970px;
  left: 104px;
  position: absolute;
  top: 0;
  width: 1711px;
}

.element .img-2 {
  height: 1720px;
  position: relative;
  width: 1920px;
}

.element .outro {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.element .group-31 {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  position: relative;
  width: 1920px;
}

.element .logo-wrapper {
  background-image: url(../../../static/img/mask-group.png);
  background-size: 100% 100%;
  height: 540px;
  position: relative;
}

.element .logo {
  height: 32px;
  left: 1657px;
  position: absolute;
  top: 391px;
  width: 144px;
}
