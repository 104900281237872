.screen {
  overflow: hidden;
  position: relative;
}

.screen .place-your-screen {
  height: 168px;
  left: 77px;
  position: relative;
  top: 322px;
  width: 223px;
}

.screen .select-and-paste {
  color: #00000080;
  font-family: "Roboto", Helvetica;
  font-size: 72px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen.with-frame {
  align-items: flex-start;
  background-color: #ffffff66;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 60px;
  display: inline-flex;
  gap: 10px;
  padding: 24px;
}

.screen.default {
  background-color: #ffffff;
  border-radius: 40px;
  height: 812px;
  width: 375px;
}

.screen.with-frame .screen-2 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 812px;
  overflow: hidden;
  position: relative;
  width: 375px;
}

.screen.default .screen-2 {
  color: #00000080;
  font-family: "Roboto", Helvetica;
  font-size: 72px;
  font-weight: 400;
  left: 77px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 321px;
}
